import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

export default function LogoGIC({width = 264, height = 64, fill = '#FFCB1F', addClass = "", ...props}) {
  return (
    <div className="df ai-e">
      <StaticImage 
        src="../../images/logo-gic-horizontal.png"
        // src="https://backoffice.comunicacaointegrada.com.br/wp-content/uploads/2022/05/logo-gic-horizontal.png"
        alt="Logo GIC"
        className={`mw-240 ${addClass}`}
      />
    </div>
  )
}