import React, { Component } from "react"
import ReCAPTCHA from "react-google-recaptcha";
import { setCookie } from "../../common/lib/useCookie.js"

import Input from "../components/input.js"

export default class FormQuestionnaire extends Component {

  constructor(props) {
    super(props)
    this.state = { 
      submitting: false,
      token: '',
      invalidToken: false,
      status: null,
    }
  }

  handleServerResponse = (ok, msg, form) => {

    this.setState({
      submitting: false,
      status: { ok, msg }
    });
    if (ok) {
      form.reset();
    }
  };

  onChange(value) {
    this.setState({token: value, invalidToken: false})
  }

  handleOnSubmit = e => {
    e.preventDefault();

    if(!this.state.token) {
      this.setState({invalidToken: true})
      return
    }

    this.setState({invalidToken: false})

    const form = e.target;
    this.setState({ submitting: true });

    var xhr, data = new FormData();

    if (window.XMLHttpRequest) {
      xhr = new XMLHttpRequest();
    } else {
      // xhr = new ActiveXObject("Microsoft.XMLHTTP");
    }

    var var_nome = document.querySelector("#gic_form #nome");
    var var_email = document.querySelector("#gic_form #e-mail");
    var var_cargo = document.querySelector("#gic_form #cargo");
    var var_empresa = document.querySelector("#gic_form #empresa");
    var var_tamanho = document.querySelector("#gic_form #tamanho");
    var var_setor = document.querySelector("#gic_form #setor");
    var var_newsletter = document.querySelector("#gic_form #newsletter");

    data.append("nome", var_nome.value);
    data.append("email", var_email.value);
    data.append("cargo", var_cargo.value);
    data.append("empresa", var_empresa.value);
    data.append("tamanho", var_tamanho.value);
    data.append("setor", var_setor.value);
    data.append("newsletter", var_newsletter.checked);

    xhr.onreadystatechange = () =>  {
      if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
        if(typeof(window.dataLayer) !== 'undefined') window.dataLayer.push({ 'event': 'form-gic-converted' });
        setCookie('gic-filled', true, 10);
        window.location.href = `${window.location.protocol}//${window.location.host}/grau-de-integracao-da-comunicacao/iniciar-teste?email=${encodeURIComponent(var_email.value)}&nome=${encodeURIComponent(var_nome.value)}`
      } else if (xhr.readyState === XMLHttpRequest.DONE) {
        this.setState({ submitting: false });
        this.handleServerResponse(false, 'Erro ao enviar o formulário', form);
      }
    };
    xhr.open("POST", "https://backoffice.comunicacaointegrada.com.br/wp-json/contact-form-7/v1/contact-forms/25344/feedback", true);
    xhr.send(data);
  };

  render() {
    const { submitting, status, invalidToken } = this.state
    return (
      <div className="col-lg-10 col-md-12 col-sm-12 bg-color-gic-yellow">
        <div className="col-10 m-auto m-mw-100vw">
          <p className="c-grey mb-1r mt-4r tac fs-body-large">
              Preencha a avaliação em 20 minutos e receba um relatório detalhado.
          </p>
          <form id="gic_form" className="mb-4r" onSubmit={this.handleOnSubmit} disabled={submitting}>
            
            <Input label="Nome" placeholder="Seu nome" required />
            <Input label="E-mail" placeholder="E-mail institucional" type="email" required />
            
            <fieldset className="dfc mb-1r">
              <label htmlFor="cargo">Cargo</label>
              <select id="cargo" name="cargo" placeholder="Cargo" required>
                <option value="Sócio/Proprietário">Sócio(a)/Proprietário(a)</option>
                <option value="Diretor">Diretor(a)</option>
                <option value="Coordenador">Coordenador(a)</option>
                <option value="Supervisor">Supervisor(a)</option>
                <option value="Especialista">Especialista</option>
                <option value="Consultor">Consultor(a)</option>
                <option value="Analista">Analista</option>
                <option value="Outros">Outros</option>
              </select>
            </fieldset>
            
            <Input label="Empresa" placeholder="Empresa" required />
            
            <fieldset className="dfc mb-1r">
              <label htmlFor="tamanho">Tamanho da organização</label>
              <select id="tamanho" name="tamanho" placeholder="Tamanho da organização" required>
                <option value="Microempresa">Microempresa: 0 a 10 funcionários</option>
                <option value="Pequeno">Pequeno porte: 10 a 100 funcionários</option>
                <option value="Médio">Médio porte:  100 a 500 funcionários</option>
                <option value="Grande">Grande porte: 500 a mais de 1000 funcionários</option>
              </select>
            </fieldset>

            <fieldset className="dfc mb-1r">
              <label htmlFor="setor">Setor de atuação</label>
              <select id="setor" name="setor" placeholder="Setor de atuação" required>
                <option value="Tecnologia">Tecnologia</option>
                <option value="Saúde">Saúde</option>
                <option value="Parceria">Energia</option>
                <option value="Educação">Educação</option>
                <option value="Serviços">Serviços</option>
                <option value="Comércio">Comércio</option>
                <option value="Outros">Outros</option>
              </select>
            </fieldset>

            <div className="form-check mb-1r">
              <input className="form-check-input" type="checkbox" value="" id="newsletter" />
              <label className="form-check-label" htmlFor="newsletter">
                Quero me cadastrar para receber a newsletter e outros conteúdos e novidades da Comunicação Integrada, podendo cancelar a inscrição quando quiser.
              </label>
            </div>
            <div className="mb-1r">
              <ReCAPTCHA
                sitekey="6LdgZbAbAAAAAGEtaZOhaLv7zfXBk_UFwlVmyfJJ"
                onChange={this.onChange.bind(this)}
              />
              { invalidToken && (<p className="fs-1r c-purple">Campo obrigatório!</p>)}
            </div>
            <button type="submit" className={`btn bg-color-gic-purple-2 w-100 jc-c ${submitting ? "sending" : ""}`}>
              <span className="spinner" role="status" aria-hidden="true"></span>
              <span className="label">Iniciar Avaliação</span>
            </button>
            {status && (
              <p className={`mt-1r ${!status.ok ? "errorMsg" : ""}`}>
                {status.msg}
              </p>
            )}
          </form>
        </div>
      </div>
    )
  }
}