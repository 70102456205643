import * as React from "react"
import Copyright from './elements/Copyright.js'

const CommonFooterGIC = () => {
  
  return (
    <footer className="bg-color-gray c-white">
      <Copyright />
    </footer>
  )
}

export default CommonFooterGIC
