import * as React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import { SEOContext } from 'gatsby-plugin-wpgraphql-seo'

import CommonHeaderGIC from "../HeaderGIC.js"
import CommonFooterGIC from "../FooterGIC.js"

export default function PageGIC({children}) {
  const {
    wp: { seo },
  } = useStaticQuery(graphql`
    query SiteInfoQueryGIC {
        wp {
            seo {
                contentTypes {
                    post {
                        title
                        schemaType
                        metaRobotsNoindex
                        metaDesc
                    }
                    page {
                        metaDesc
                        metaRobotsNoindex
                        schemaType
                        title
                    }
                }
                webmaster {
                    googleVerify
                    yandexVerify
                    msVerify
                    baiduVerify
                }
                schema {
                    companyName
                    personName
                    companyOrPerson
                    wordpressSiteName
                    siteUrl
                    siteName
                    inLanguage
                    logo {
                        sourceUrl
                        mediaItemUrl
                        altText
                    }
                }
                social {
                    facebook {
                        url
                        defaultImage {
                            sourceUrl
                            mediaItemUrl
                        }
                    }
                    instagram {
                        url
                    }
                    linkedIn {
                        url
                    }
                    mySpace {
                        url
                    }
                    pinterest {
                        url
                        metaTag
                    }
                    twitter {
                        username
                    }
                    wikipedia {
                        url
                    }
                    youTube {
                        url
                    }
                }
            }
        }
    }
  `);

  return (
    <SEOContext.Provider value={{ global: seo }}>
      <main>
        <CommonHeaderGIC />
        {children}
        <CommonFooterGIC /> 
      </main>
    </SEOContext.Provider>
  )
}