import * as React from "react"
import { Link } from "gatsby"
import LogoGIC from '../icons/LogoGIC.js'
import Logo from '../icons/Logo.js'

const NavbarGIC = () => {
  return (
    <nav className="navbar navbar-expand-xl navbar-dark bg-color-gray" role="navigation" aria-label="main navigation">
      
        <div className="container-fluid container-xg">
          <div className="navbar-brand df">
            <LogoGIC />
            <Link to="/" className="ml-1r">
              <p className="c-white tac mb-0 fs-1r"><small>Desenvolvido por</small></p>
              <Logo width="100%" />
            </Link>
          </div>
        </div>
      
    </nav>
  )
}

export default NavbarGIC
