import * as React from "react"
import NavbarGIC from './elements/NavbarGIC.js'
import HeaderNotification from './elements/HeaderNotification.js'

const CommonHeaderGIC = () => {
  
  return (
    <header>
      <HeaderNotification />
      <NavbarGIC />
    </header>
  )
}

export default CommonHeaderGIC
