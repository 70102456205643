import React, { Component } from "react"
import Container from "../../common/helpers/Container.js"
import PageGIC from "../../common/elements/PageGIC.js"
import FormQuestionnaire from "../../common/elements/FormQuestionnaire.js"
import ContainerWithBg from "../../common/helpers/ContainerWithBg.js"
import { StaticImage } from "gatsby-plugin-image"

export default class IndiceDeIntegracaoDaComunicacao extends Component {

  constructor(props) {
    super(props)
    this.state = { 
    }
  }

  render () {
    return (
      <PageGIC>
        <ContainerWithBg className="container py-4 py-lg-8" classBgName="bg-color-gray">
          <div className="row">
            <div className="col-12 col-md-6 dfc jc-c align-items-center fc-r tac c-white">
              <StaticImage src="../../images/gic/gic-mock.png" alt="20 minutos para preencher" />
              <h1 className="fw-500 mt-2r">Grau de Integração da Comunicação® (GIC)</h1>
              <h2 className="h3 fw-500 tac mt-2r">Uma Metodologia inédita para avaliar o quanto a Comunicação da sua empresa dialoga com as Estratégias de Negócio e possui papel relevante nos Resultados Corporativos, em médio e longo prazo.</h2>
            </div>
            <div className="col-md-6 d-flex align-items-center jc-c" id="form-gic">
              <FormQuestionnaire  />
            </div>
          </div>
        </ContainerWithBg>

        <Container className="container-xg py-4 py-lg-8">
          <div className="container">
            <div className="row">
              <h3 className="h2 c-purple mb-2r mt-1 mt-md-3 tac">Como funciona o GIC?</h3>
              <p className="fs-body-large fw-500 tac mt-2r">Primeiramente, é preciso preencher seus dados e os de sua empresa; em seguida, deve-se responder às 64 perguntas, com o tempo estimado de 20 minutos. Dessa forma, você visualizará o Grau de Integração e algumas recomendações específicas para cada um dos 22 Requisitos Essenciais (RE). Ao final, um relatório detalhado e completo da Auditoria 360º e materiais ricos serão enviados para o e-mail cadastrado.</p>
              
              <Container className="py-4 py-lg-8">
                <div className="row justify-content-center">
                  <div className="col-8 row jc-sb py-2 jcm-c">
                    <div className="col-md-4 col-lg-3 col-sm-8">
                      <StaticImage src="../../images/gic/icone-questoes.png" alt="64 perguntas rápidas" />
                      <p className="tac">64 perguntas <br /> rápidas</p>
                    </div>
                    <div className="col-md-4 col-lg-3 col-sm-8">
                      <StaticImage src="../../images/gic/icone-tempo.png" alt="20 minutos para preencher" />
                      <p className="tac">20 minutos para preencher</p>
                    </div>
                    <div className="col-md-4 col-lg-3 col-sm-8">
                      <StaticImage src="../../images/gic/icone-recomendacoes.png" alt="22 áreas que receberão recomendações estratégicas" />
                      <p className="tac">Áreas que receberão recomendações estratégicas</p>
                    </div>
                  </div>
                </div>
              </Container>
              <div className="">
                <a href="#form-gic" className="btn bg-color-gic-purple-2 jc-c m-auto w-a lh-14">Quero receber meu relatório sobre o Grau de Integração da Comunicação®</a>
              </div>
            </div>
          </div>
        </Container>
        
        <Container className="container-fluid">
          <div className="row">
            <div className="col-md-8 bg-color-golden d-flex align-items-center py-4 py-lg-8">
              <div className="col-size-720">
              <h3 className="h2 c-purple mb-2r mt-1 mt-md-3 tac">Para quem é?</h3>
              <p className="fw-500 tac mt-2r fs-body-large">Desenvolvido pelo time da Comunicação Integrada, especializado em Diagnóstico 360º, o GIC é indicado para médias e grandes empresas que desejam entender os desafios atuais e tornar a comunicação mais estratégica e valorizada na organização.</p>
              <a href="#form-gic" className="btn bg-color-gic-purple-2 jc-c mt-2r">Iniciar o teste agora</a>
              </div>
            </div>
            <div className="col-md-4 d-flex p-0 bg-pattern bg-pattern-gradient">
              <StaticImage src="../../images/gic/gic-para-quem.jpg" alt="GIC Para Quem" />
            </div>
          </div>
        </Container>
      </PageGIC>
    )
  }
}